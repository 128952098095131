import "./AnswerView.scss"
import React, {HTMLAttributes, useEffect, useState} from 'react';
import {AnswerType, AnswerWithIdType} from "../../../types/AnswerTypes";
import ViewRating from "../../ratings/ViewRating/ViewRating";
import LongText from "../../LongText/LongText";
import Timestamp from "../../Timestamp/Timestamp";
import CreateRating from "../../ratings/CreateRating/CreateRating";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";
import Button from "react-bootstrap/Button";
import {FaAngleDown, FaAngleUp} from "react-icons/fa";
import {TemplatedText} from "../../../types/TemplatedText";
import {TextPlaceholder, TextPlaceholderSubstitution} from "../../../types/TextPlaceholder";
import {PlaceholderSubstitutionCtx} from "../../../context/PlaceholderSubstitutionCtx/PlaceholderSubstitutionCtx";

interface UsedPromptProps {
    answer_id: number
}

function UsedPrompt({answer_id}: UsedPromptProps) {
    const [prompt, setPrompt] = useState<TemplatedText>(new TemplatedText("", []))
    const [substitutions, setSubstitutions] = useState<TextPlaceholderSubstitution>({})

    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token ?? '')

    useEffect(() => {
        db.getUsedPromptAndSubstitution(answer_id)
            .then(({prompt, substitution}) => {
                setPrompt(prompt)
                setSubstitutions(substitution)
            })
    }, [answer_id, db])

    const handleSetSubstitution = () => {
        // do nothing
    }

    return <PlaceholderSubstitutionCtx.Provider value={{substitutions, setSubstitution: handleSetSubstitution}}>
        <LongText text={prompt.text}
                  placeholders={TextPlaceholder.findPlaceholders(prompt.text)}
                  editable={false}
                  className={'answer-view-used-prompt-component'}
        />
    </PlaceholderSubstitutionCtx.Provider>
}

interface AnswerNotesProps {
    answer: AnswerType
}

function AnswerNotes({answer}: AnswerNotesProps) {
    const [showNotes, setShowNotes] = useState<boolean>(false)

    return <div>
        <Button className={'inline-button button-no-color link-text'}
                onClick={() => setShowNotes(!showNotes)}>
            {
                showNotes ?
                    <span>Notizen <FaAngleUp/></span>
                    :
                    <span>Notizen <FaAngleDown/></span>
            }

        </Button>
        <div className={`answer-view-notes ${showNotes ? 'expanded' : 'collapsed'}`}
             aria-hidden={!showNotes}
        >
            {answer.notes}
        </div>
    </div>
}

interface AnswerViewProps extends HTMLAttributes<HTMLDivElement> {
    answer: AnswerWithIdType,
    onSubmitRating?: (answer: AnswerWithIdType, rating: number) => void
}

/**
 * This component is a visual representation of an answer given by an llm
 *
 * @param answer the answer that should be visualised
 * @param onSubmitRating callback function that is called when a new rating is submitted by the current user.
 *      It is called with the current answer and the new rating.
 * @param props other properties that are directly passed to the top-level div component.
 * @constructor
 */
function AnswerView({answer, onSubmitRating, ...props}: AnswerViewProps) {
    const [currentUserRating, setCurrentUserRating] = useState<number>(0)
    const [showUsedPrompt, setShowUsedPrompt] = useState<boolean>(false)

    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token ?? '')

    useEffect(() => {
        db.getReview(answer.id, auth.user?.profile.preferred_username ?? '').then(setCurrentUserRating)
    }, [db, answer.id, auth.user?.profile.preferred_username])

    return (
        <div {...props} className={'answer-view ' + (props.className ?? '')}>
            <div className={'answer-view-header'}>
                <h6 className={'answer-view-heading'}>
                    {answer.llm_name} antwortet:
                </h6>
                <ViewRating avgRating={answer.avg_rating ?? 0} numRatings={answer.num_ratings ?? 0}/>
            </div>
            <LongText text={answer.text} maxHeight={190}/>
            <div className={'answer-view-footer'}>
                <Timestamp timestamp={answer.created_at}
                           timestampTextTemplate={"erzeugt am {{TIMESTAMP}}"}
                           className={'answer-view-timestamp footnote-text'}
                />
                {
                    onSubmitRating !== undefined
                    && <div className={'comment-add-rating'}>
                        <CreateRating onSubmitRating={(rating) => onSubmitRating(answer, rating)}
                                      currentRating={currentUserRating}
                        />
                    </div>
                }
            </div>
            <Button className={'inline-button button-no-color link-text'}
                    onClick={() => setShowUsedPrompt(!showUsedPrompt)}>
                {
                    showUsedPrompt ?
                        <span>Genutzter Prompt (v{answer.prompt_version}) ausblenden <FaAngleUp/></span>
                        :
                        <span>Genutzter Prompt (v{answer.prompt_version}) anzeigen <FaAngleDown/></span>
                }

            </Button>
            {showUsedPrompt && <div>
                <div className={'label'}>
                    Version {answer.prompt_version}
                </div>
                <div className={'answer-view-used-prompt'}>
                    <UsedPrompt answer_id={answer.id}/>
                </div>
            </div>}
            {
                answer.notes.length > 0 && <AnswerNotes answer={answer}/>
            }
        </div>
    )
}

export default AnswerView;