import React, {useState} from 'react';
import './App.scss';
import {hasAuthParams, useAuth} from "react-oidc-context";
import {Outlet} from "react-router-dom";
import Header from "./components/Header/Header";

function App() {
    const auth = useAuth();
    const [silentSignInFailed, setSilentSignInFailed] = useState<boolean>(false)

    // automatically sign-in
    React.useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !silentSignInFailed) {
            auth.signinSilent({
                redirect_uri: window.location.href
            }).catch(() => {
                setSilentSignInFailed(true)
            });
        }
    }, [auth, silentSignInFailed]);

    // display nice messages while the authentication process is running
    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Anmeldung wird durchgeführt...</div>;
        case "signoutRedirect":
            return <div>Abmeldung wird durchgeführt</div>;
    }

    // display a login button if not authenticated
    if (!auth.isAuthenticated) {
        return <button onClick={() => void auth.signinRedirect({
            redirect_uri: window.location.href
        })}>Log in</button>;
    }


    // user is authenticated
    return (
        <div className="App">
            <div className={'Foreground'}>
                <div className={'header'}>
                    <Header/>
                </div>
                <div className={'content'}>
                    <Outlet/>
                </div>
                <div className={'footer'}>

                </div>
            </div>
        </div>
    );
}

export default App;
